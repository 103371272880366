import type { ChangeEvent, JSX } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import { Radio, RadioGroup } from '@change-corgi/design-system/components/forms';
import { Box } from '@change-corgi/design-system/layout';

import { useMarketingConsentStep } from './hooks/useMarketingConsentStep';

export function MarketingConsentStep(): JSX.Element | null {
	const {
		data: { marketingConsent },
		actions: { setStepData, submitStep },
	} = useMarketingConsentStep();
	const { translate } = useI18n();

	const onChange = (event: ChangeEvent<HTMLInputElement>) => {
		setStepData({ marketingConsent: event.target.value === 'yes' });
	};

	return (
		<Box ml={40}>
			<form
				onSubmit={(event) => {
					event.preventDefault();
					void submitStep();
				}}
				data-testid="marketing-consent-form"
				data-qa="marketing-consent-form"
			>
				<RadioGroup id="marketing-consent">
					<Box mt={16}>
						<Radio
							label={translate('corgi.login_or_signup.common.labels.true')}
							value="yes"
							size="big"
							name="marketing-consent"
							data-testid="marketing-consent-optIn-radio"
							data-qa="marketing-consent-optIn-radio"
							checked={marketingConsent === true}
							onChange={onChange}
						/>
					</Box>

					<Box mt={16}>
						<Radio
							label={translate('corgi.login_or_signup.common.labels.false')}
							value="no"
							size="big"
							name="marketing-consent"
							data-testid="marketing-consent-optOut-radio"
							data-qa="marketing-consent-optOut-radio"
							checked={marketingConsent === false}
							onChange={onChange}
						/>
					</Box>
				</RadioGroup>
				{/* to handle submission by pressing enter */}
				<input type="submit" sx={{ display: 'none' }} />
			</form>
		</Box>
	);
}
