import type { ChangeEvent, JSX } from 'react';
import { useEffect } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import { Input } from '@change-corgi/design-system/components/forms';
import { Box } from '@change-corgi/design-system/layout';

import { useNameStep } from './hooks/useNameStep';

export function NameStep(): JSX.Element {
	const {
		data: { firstName, lastName, firstNameError, lastNameError },
		actions: { trackStepView, setStepData, submitStep },
	} = useNameStep();

	const { translate } = useI18n();

	useEffect(() => {
		trackStepView();
	}, [trackStepView]);

	return (
		<Box px={[16, 0]}>
			<form
				onSubmit={(event) => {
					event.preventDefault();
					void submitStep();
				}}
			>
				<Box mb={16}>
					<Input
						id="firstName"
						type="text"
						name="firstName"
						data-testid="firstName"
						data-qa="firstName"
						size="large"
						label={translate('corgi.login_or_signup.common.labels.first_name')}
						onChange={(event: ChangeEvent<HTMLInputElement>) => setStepData({ firstName: event.target.value })}
						value={firstName}
						error={firstNameError}
					/>
				</Box>
				<Box mb={16}>
					<Input
						id="lastName"
						type="text"
						name="lastName"
						data-testid="lastName"
						data-qa="lastName"
						size="large"
						label={translate('corgi.login_or_signup.common.labels.last_name')}
						onChange={(event: ChangeEvent<HTMLInputElement>) => setStepData({ lastName: event.target.value })}
						value={lastName}
						error={lastNameError}
					/>
				</Box>
				{/* to handle submission by pressing enter */}
				<input type="submit" sx={{ display: 'none' }} />
			</form>
		</Box>
	);
}
