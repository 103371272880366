import type { ChangeEvent, JSX } from 'react';

import { Translate, useI18n } from '@change-corgi/core/react/i18n';
import { FakeLink } from '@change-corgi/design-system/components/actions';
import { Input } from '@change-corgi/design-system/components/forms';
import { Box } from '@change-corgi/design-system/layout';

import { useLoginEmailForm } from './hooks/useLoginEmailForm';

export function LoginEmailForm(): JSX.Element {
	const {
		data: { email, emailError, identified },
		actions: { submitStep, setStepData, changeUser },
	} = useLoginEmailForm();
	const { translate } = useI18n();

	return (
		<>
			<form
				onSubmit={(event) => {
					event.preventDefault();
					void submitStep();
				}}
			>
				<Input
					id="email"
					type="text"
					name="email"
					size="large"
					data-testid="email"
					data-qa="email"
					label={translate('corgi.login_or_signup.common.labels.email')}
					onChange={(event: ChangeEvent<HTMLInputElement>) => setStepData({ email: event.target.value })}
					value={email}
					error={emailError}
					readOnly={identified}
				/>
			</form>
			{identified && (
				<Box mt={8}>
					<FakeLink onClick={changeUser} data-testid="forgot-password" data-qa="forgot-password">
						{/* TODO rename key to non sign-specific key */}
						<Translate value="fe.components.petition_sign.not_you" />
					</FakeLink>
				</Box>
			)}
		</>
	);
}
