import { useEffect } from 'react';
import type { JSX } from 'react';

import { Translate, TranslateHtml } from '@change-corgi/core/react/i18n';
import { FakeLink, Link } from '@change-corgi/design-system/components/actions';
import { Box } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { useEmailVerificationStep } from './hooks/useEmailVerificationStep';

export function EmailVerificationStep(): JSX.Element {
	const {
		data: { supportUrl },
		actions: { trackStepView, useDifferentEmail },
	} = useEmailVerificationStep();

	useEffect(() => {
		trackStepView();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box px={[16, 0]}>
			<Text as="p" size="large">
				<TranslateHtml value="corgi.login_or_signup.verify_email.help.title" />
			</Text>
			<Box ml={24}>
				<ul
					sx={{
						margin: 0,
						listStyleType: 'circle',
						// eslint-disable-next-line @typescript-eslint/naming-convention
						'> li': {
							my: 12,
							mx: 8,
						},
					}}
				>
					<li>
						<Text as="p">
							<Translate value="corgi.login_or_signup.verify_email.help.tip1" />
						</Text>
					</li>
					<li>
						<FakeLink onClick={useDifferentEmail} data-testid="use-different-email" data-qa="use-different-email">
							<Translate value="corgi.login_or_signup.verify_email.help.tip3" />
						</FakeLink>
					</li>
					<li>
						<Link to={supportUrl} target="_blank" data-testid="contact-support" data-qa="contact-support">
							<Translate value="corgi.login_or_signup.verify_email.help.contact_support" />
						</Link>
					</li>
				</ul>
			</Box>
		</Box>
	);
}
