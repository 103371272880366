import { useEffect } from 'react';
import type { ChangeEvent, JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { FakeLink } from '@change-corgi/design-system/components/actions';
import { Box } from '@change-corgi/design-system/layout';

import { PasswordInput } from '../PasswordInput';

import { useLoginStep } from './hooks/useLoginStep';

export function LoginStep(): JSX.Element {
	const {
		data: { password, passwordError },
		actions: { setStepData, submitStep, trackStepView, sendResetPasswordLink },
	} = useLoginStep();

	useEffect(() => {
		trackStepView();
	}, [trackStepView]);

	return (
		<Box px={[16, 0]} py={16}>
			<form
				onSubmit={(event) => {
					event.preventDefault();
					void submitStep();
				}}
			>
				<PasswordInput
					onChange={(event: ChangeEvent<HTMLInputElement>) => setStepData({ password: event.target.value })}
					value={password}
					error={passwordError}
				/>
			</form>
			<Box mt={8}>
				<FakeLink onClick={sendResetPasswordLink} data-testid="forgot-password" data-qa="forgot-password">
					<Translate value="corgi.login_or_signup.common.labels.forgot_password" />
				</FakeLink>
			</Box>
		</Box>
	);
}
